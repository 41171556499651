* {
    color: white;
    font-family: 'Poppins';
}

#navbar {
  position: fixed;
  background: rgba(0, 0, 0, 0.082);
  display: flex;
  width: 100%;
  top: 0;
  padding: 10px;
}

.logo {
  position: relative;
  left: 10px;
  padding: 0 20px; 
  font-size: 30px;
  color: whitesmoke;
}

.logo p {
  color: #fff;
  filter: invert();
  font-family: 'Satisfy';
}

.lists {
    position: relative;
    top: 0px;
    right: 10px;
    width: 410px;
    display: flex;
    gap: 0.5rem;
    font-size: 20px;
}


