#main {
    background: radial-gradient(rgb(62, 58, 58), rgb(0, 0, 0));
    height: 95.8vh;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: 80%;
    width: auto;
}

.bigcongratulations {
    text-align: center;
    margin: auto;
    font-size: 67px;
    font-weight: bold;
    font-family: "Satisfy";
}

.youscored {
    text-align: center;
    font-size: 2.1rem;
    font-family: 'Poppins';
}

.score {
    font-size: 80px;
}

button {
    background: none;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    border: 2px solid white;
    border-radius: 25px;
}

button:hover {
    background: white;
    color: rgb(0, 0, 0);
}