#main {
    background: radial-gradient(rgb(62, 58, 58), rgb(0, 0, 0));
    min-height: 95.8vh;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
}

#area {
    min-width: 390px;
    width: 40%;
    background-color: rgba(208, 131, 31, 0);
}

.logo-section {
    margin: auto;
    width: 20%;
    min-width: 250px;
    text-align: center;
    font-size: 40px;
    font-family: 'Satisfy';
    font-weight: bolder;
}

.logo-section h1 {
    margin-top: -15px;
    margin-bottom: -15px;
}

.logo {
    width: 85%;
    filter: invert();
    margin: auto;
}

.desc {
    font-family: 'Poppins';
    font-size: 22px;
    width: 40%;
    min-width: 390px;
    text-align: center;
    margin: auto;
}

.startnow {
    background: none;
    color: white;
    padding: 10px 20px;
    font-size: 22px;
    border: 2px solid white;
    border-radius: 25px;
}

.startnow:hover {
    background: white;
    color: rgb(0, 0, 0);
}